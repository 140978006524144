<template>
  <!-- stadiumone -->
  <div>
    <div id="amap"></div>
    <div id="main-shadow"></div>
    <!-- 顶部信息栏 -->
    <div class="top gray">
      <div id="homeTitle" class="font18">{{ title }}</div>
    </div>
    <div class="appnav">
      <div class="appleft">
        <div
          class="window-echarts-open-btn"
          @click="windowEchartsBtnClick($event)"
        >
          <div class="appnav4"></div>
        </div>
        <div class="appnav1" @click="navClick"><span>主页</span></div>
        <div class="appnav1" @click="navClick"><span>云健身房</span></div>
        <div class="appnav1" @click="navClick"><span>智慧化健身房</span></div>
        <div class="appnav1 selected"><span>体育场馆</span></div>
      </div>
      <div class="appright">
        <div class="appnav2" @click="navClick"><span>游步道</span></div>
        <div class="appnav2" @click="navClick"><span>赛事</span></div>
        <div class="appnav2" @click="navClick"><span>体育产业</span></div>
        <div class="appnav2" @click="navClick"><span>体育协会</span></div>
        <div class="window-big-btn open" @click="fullScreenClick($event)">
          <div class="appnav4"></div>
        </div>
      </div>
    </div>
    <div class="time">
      <span>{{ titleDate }}</span>
      <span>{{ titleWeek }}</span>
      <span>{{ titleTime }}</span>
    </div>
    <div class="quit" @click="quitShow = true">退出</div>
    <div class="posit-left" v-if="!sonShow">
      <stadiumlefttop
        style="margin-bottom: 0.2rem"
        v-if="MapShow"
      ></stadiumlefttop>
      <trafficrank></trafficrank>
    </div>
    <div v-if="!sonShow">
      <stadiumsidebar></stadiumsidebar>
    </div>
    <div v-if="sonShow">
      <stadiumtwo></stadiumtwo>
    </div>
    <div class="quitTip" v-if="quitShow">
      <p>提示</p>
      <p style="font-size: 0.16rem">确定退出？</p>
      <div style="display: flex; justify-content: space-around">
        <div class="quitCofirm" @click="quit">确定</div>
        <div class="quitCancle" @click="quitShow = false">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import { config, upDataMap, ajaxRequest } from "../../assets/js/config.js";
import { amap } from "../../assets/js/mapVenues.js";
import { show } from "../../assets/js/animate.js";
import lotteryjson from "/public/data/lottery.json";
import stadiumlefttop from "../charts/stadiumone/stadiumlefttop.vue";
import trafficrank from "../charts/stadiumone/trafficrank.vue";
import stadiumsidebar from "../charts/stadiumone/stadiumsidebar.vue";
import sportscomplex from "../charts/stadiumtwo/sportscomplex.vue";
import venuebooking from "../charts/stadiumtwo/venuebooking.vue";
import stadiumtwo from "./stadiumtwo.vue";
import bus from "/src/assets/js/bus.js";
import { getAjax, timestamp } from "../../assets/js/websocket";
import loadJS from "../../util/load";

export default {
  components: {
    stadiumlefttop,
    trafficrank,
    stadiumsidebar,
    sportscomplex,
    venuebooking,
    stadiumtwo,
  },
  data() {
    return {
      // 标题
      title: window.PageTitle,
      // 标题时间
      titleTime: "",
      // 标题日期
      titleDate: "",
      // 标题周
      titleWeek: "",
      quitShow: false,
      sonShow: false,
      MapShow: false,
    };
  },
  methods: {
    initAmap() {
      window.initAmap2();
    },
    // 标题栏时间
    getDate() {
      var that = this;
      setInterval(function () {
        var shwoDate = window.showDate();
        that.titleTime = shwoDate.titleTime;
        that.titleWeek = shwoDate.titleWeek;
        that.titleDate = shwoDate.titleDate;
      }, 1000);
    },
    logOut() {
      let screenUserInfo = JSON.parse(
        window.localStorage.getItem("screenUserInfo")
      );
      let data = {
        token: window.getToken(),
      };
      getAjax({
        url: "/map/screen/logout?token=" + screenUserInfo.token,
        method: "GET",
        data: data,
        success: function (res) {
          // console.log(res)
        },
      });
    },
    // 退出
    quit() {
      let that = this;
      // 清除用户信息
      that.logOut();
      window.localStorage.removeItem("screenUserInfo");
      //   this.$cookies.remove("screenUserInfo");
      that.$router.push("/");
      that.quitShow = false;
      location.reload();
    },
    // 导航栏点击事件
    navClick(e) {
      // console.log(e.target.innerText)
      this.$router.push({
        path: "/home?content=" + e.target.innerText,
        // params: {
        //     content: e.target.innerText,
        // }
      });
    },
    // 全屏
    fullScreenClick(event) {
      window.fullScreenClick(event);
    },
    // 显示隐藏
    windowEchartsBtnClick(event) {
      window.echartsBtnClick(event);
    },
  },
  mounted() {
    loadJS(
      "https://webapi.amap.com/maps?v=1.4.15&key=19f93ce96f71a94a0f260817fe096350&plugin=Map3D,AMap.DistrictLayer,AMap.Scale,AMap.ToolBar",
      "Map3D"
    ).then(() => {
      setTimeout(() => {
        this.initAmap();
        this.MapShow = true;
      }, 0);
    });
    window.windowResize();
    // console.log(lotteryjson.gymnasium)
    // this.plug.upDatavenues(lotteryjson.gymnasium)
    // show(showdata, _this)
    $("#amap").show();
    $("#main-border").hide();
    this.getDate();
    bus.$on("venuesName", (res) => {
      // console.log(res)
      this.sonShow = true;
    });
    bus.$on("backFather", (res) => {
      this.sonShow = false;
      // this.$forceUpdate()
    });
  },
  beforeDestroy() {
    bus.$off();
    const Map3D = document.getElementById("Map3D");
    document.head.removeChild(Map3D);
  },
};
</script>
<style scoped rel="stylesheet/less">
@import url("../../assets/css/index.css");
</style>

