<template>
  <div class="about">
        <!-- <div id="swiper" class="swiper-container swiper-no-swiping">
            <div class="swiper-wrapper">
                <div class="swiper-slide fz" v-for="(item, index) in stadium" :key="index">{{item}}</div>
            </div>
        </div> -->
        <div class="darkBack list">
            <p class="listTitle">体育场馆</p>
            <vueSeamlessScroll :data="stadium" class="seamlessWarp" :class-option="classOption">
				<table id="venuesList">
					<tr v-for="(item,index) in stadium" :key="index">
						<td class="roomName" @click="to_gym(item)">{{item.title}}</td>
					</tr>
				</table>
			</vueSeamlessScroll>
        </div>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import {getAjax, timestamp} from "../../../assets/js/websocket";
import bus from '/src/assets/js/bus.js'
import Swiper from 'swiper'
import lotteryjson from '/public/data/lottery.json'
import {config,upDataMap,ajaxRequest} from '../../../assets/js/config.js'
import {amap} from '../../../assets/js/mapVenues.js'

export default {
    components:{
        vueSeamlessScroll,
    },
    data () {
        return {
            stadium:[],
            data:[]
        }
    },
    methods: {
        getList(){
            let that = this;
			let info = JSON.parse(window.localStorage.getItem("screenUserInfo"));
            let url;
            if(info.is_city == 1) url = "/map/screen/getSportsVenue";
            else url = "/map/screen/getSportsVenue?area_id="+info.area_id;
			var data = {
				token: window.getToken(),
				app_id: window.appId,
				time: timestamp(),
			}
			getAjax({
				url: url,
				method:"GET",
				data:data,
				success:function(res){
					// console.log(res)
                    // that.list.stadium = res.data;
                    if(res.data.length>0){
                        that.data = res.data;
                        that.stadium = res.data;
                        lotteryjson.gymnasium.data.center = [res.data[0].lng, res.data[0].lat]
                    }
                    // console.log(lotteryjson.gymnasium)
                    that.plug.upDatavenues(lotteryjson.gymnasium)
                    // that.stadium = [];
                    // res.data.forEach((item,index)=>{
                    //     that.stadium.push(item.title)
                    // })
                    // console.log(that.stadium)
                    // that.$nextTick(()=>{
                    //     that.createSwipe(that.stadium.length);
                    // })
                    // that.stadium = res.data;
				},
			})
        },
        createSwipe(num){
            let that = this;
            new Swiper ('.swiper-container', {
                slidesPerView: num,
                spaceBetween: 30,
                centeredSlides: true,
                slideToClickedSlide:true,
                loop: true,
                noSwiping : false,
                direction: 'vertical',
                pagination: {
                    el: '.swiper-container',
                    clickable: true,
                },
                onSlideChangeEnd: function(swiper){                    // 获取当前标签的html
                    var txt = $('.swiper-slide-active').html();
                    // console.log(txt)
                    // alert(txt)
                },
                onSlideChangeEnd: function(swiper){
                    // console.log($('.swiper-slide-active').html())
                    that.data.forEach((item,index)=>{
                        if(item.title == $('.swiper-slide-active').html()){
                            // console.log($('.swiper-slide-active').html())
                            lotteryjson.gymnasium.data.center = [item.lng, item.lat]
                            // console.log(lotteryjson.gymnasium)
                            that.plug.upDatavenues(lotteryjson.gymnasium)
                            setTimeout(() => {
                                bus.$emit("venue_num",index)
                            }, 200);
                        }
                    })
                    
                }
            })
        },
        // 前往健身房子页面
        to_gym(data){
            let that = this;
            // console.log(data)
            that.data.forEach((item,index)=>{
                if(item.title == data.title){
                    // console.log($('.swiper-slide-active').html())
                    lotteryjson.gymnasium.data.center = [item.lng, item.lat]
                    // console.log(lotteryjson.gymnasium)
                    that.plug.upDatavenues(lotteryjson.gymnasium)
                    setTimeout(() => {
                        bus.$emit("venue_num",index)
                    }, 200);
                }
            })
            // this.$router.push({
            //     path: `/swimming?id=${data.id}&title=${data.title}`
            // })
        }
    },
    computed: {
        classOption () {
            return {
                step: 0.4, // 数值越大速度滚动越快
                // limitMoveNum: this.member.membersInfo.length, // 开始无缝滚动的数据量 this.dataList.length
                limitMoveNum: 18, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
            }
        }
    },
    mounted() {
        this.getList();
    },
};
</script>

<style scoped>

.list{
    width: 3.26rem;
    height: 6.62rem;
    position: absolute;
    top: 1.4rem;
    right: 0.5rem;
    padding: 0.15rem 0.34rem;
}
.listTitle{
    color: white;
    font-size: 0.18rem;
    margin-bottom: 0.3rem;
}
.roomName{
    color: #7EA7E2 ;
    font-size: 0.14rem;
    line-height: 0.28rem;
}
.seamlessWarp{
    width: 2.8rem;
    height: 5.7rem;
    overflow: hidden;
}

.about{
    position: relative;
    z-index: 200;
}
.swiper-container {
    width: 4.00rem;
    height: 5.00rem;
    /* border:1px solid red; */
    position: absolute;
    right: -1.00rem;
    top:1.50rem;
}

.swiper-slide {
    text-align: left;
    padding-left:0.4rem;
    box-sizing: border-box;
    font-size: 0.33rem;
    /* background: #1CE8D860; */
    background-image: linear-gradient(90deg, #1ce8d840 20%,#1ce8d810 80%);
    border-radius: 0.60rem;
    /* Center slide text vertically */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    transition: 300ms;
    transform: scale(0.6);
    color: #FFFFFF;
    cursor: default;
    border:0.02rem solid #1ce8d8;
    opacity: 0.5;
}

.swiper-slide-active,
.swiper-slide-duplicate-active {
    transform: scale(1);
    opacity: 1;
}


.swiper-slide-next,.swiper-slide-prev {
    transform: scale(0.8);
    opacity: 0.7;
}
.fz{
    font-size: 0.24rem;
}
</style>