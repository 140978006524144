 <template>
<!-- 场馆预定数据 -->
    <div class="venue darkBack">
        <div>场馆预定数据</div>
        <div class="venuecon">
            <div class="venuetop">
                <div>
                    <p>{{data.headcount.name}}</p>
                    <div>
                        <countTo :startVal='data.headcount.preNum' :endVal='data.headcount.number' :duration='3000'></countTo>
                    </div>
                </div>
                <div>
                    <p>{{data.total.name}}</p>
                    <div>
                        <countTo :startVal='data.total.preNum' :endVal='data.total.number' :duration='3000'></countTo>
                    </div>
                </div>
            </div>
            <div class="venuebot">
                <div v-for="(sports, index) in sportevent" :key="index">
                    <span>{{sports.sport}}</span>
                    <span>{{sports.number}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import countTo from 'vue-count-to';
import {getAjax, timestamp} from "../../../assets/js/websocket";

export default {
    components: { countTo },
    data () {
        return {
            data:{
                headcount:{ name: "预定场地总人数", number: 0,preNum:0},
                total:{ name: "预定场地总时长(分)", number: 0,preNum:0},
            },
            sportevent:[
                {sport: "羽毛球场", number: "5个"},
                {sport: "篮球场", number: "5个"},
                {sport: "网球场", number: "2个"},
                {sport: "足球场", number: "1个"},
                {sport: "健身设施", number: "112个"},
                {sport: "跑道", number: "800米"},  
            ],
            dataTimer:""
        }
    },
    methods: {
        getVenueData(){
			let that = this;
			var data = {
				token: window.getToken(),
				app_id: window.appId,
				time: timestamp(),
			}
			getAjax({
				url: "/map/screen/getSportsVenue",
				method:"GET",
				data:data,
				success:function(res){
					// console.log(res)
                    res.data.forEach((item,index)=>{
                        if(item.title == window.venueName){
                            that.data.headcount.preNum = that.data.headcount.number;
                            that.data.total.preNum = that.data.total.number;
                            that.data.headcount.number = parseFloat( item.reserve_num);
                            that.data.total.number = parseFloat(item.reserve_time_num);
                            that.sportevent = [];
                            item.project_ids.forEach(son=>{
                                that.sportevent.push({
                                    sport:son.industry_name,
                                    number:son.industry_num
                                })
                            })
                        }
                    })
				},
			})
		}
    },
    mounted () {
        this.getVenueData();
        this.dataTimer = setInterval(() => {
            this.getVenueData();
        }, 3000);
    },
    beforeDestroy(){
        clearInterval(this.dataTimer)
    }
}
</script>

<style scoped>
.venue{
    width:5.05rem;
    height:4.91rem;
    padding:0.2rem;
    position: relative;
    color:#ffffff;
    font-size: 0.20rem;
}

.venuecon{
    width:4.30rem;
    height:0.73rem;
    margin: auto;
}
.venuetop{
    font-size: 0.14rem;
    color:#A8DAEB;
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin:0.2rem 0rem;
}
.venuetop>div{
    width:2.00rem;
    height:0.73rem;
    background:url('../../../assets/images/borderBack.png') no-repeat;
    background-size: 100% 100%;
}
.venuetop>div>p:nth-child(1){
    margin-top:0.10rem;
}

.venuetop>div>div{
    font-size: 0.26rem;
    color:#20D7FE;
}
.venuebot{
    width:4.30rem;
    height:2.76rem;
    font-size: 0.18rem;
    color:#7EA7E2;
    overflow: hidden;
}
.venuebot>div>span{
    display: inline-block;
}
.venuebot>div>span:nth-child(1){
    line-height: 0.46rem;
    width:1.36rem;
}
</style>