<template>
<!-- 越城区体育中心 -->
    <div class="complex darkBack">
        <!-- <div>越城区体育中心</div> -->
        <div class="complexcon">
            <div class="complextop">
                <div>
                    <p>{{area.name}}</p>
                    <div>
                        <countTo :startVal='area.number' :endVal='area.number' :duration='3000'></countTo>
                    </div>
                </div>
                <div>
                    <p>{{ridership.name}}</p>
                    <div>
                        <countTo :startVal='ridershipStart' :endVal='ridership.number' :duration='3000'></countTo>
                    </div>
                </div>
                <div>
                    <p>{{monthData.name}}</p>
                    <div>
                        <countTo :startVal='monthStart' :endVal='monthData.number' :duration='3000'></countTo>
                    </div>
                </div>
                <div>
                    <p>{{yearData.name}}</p>
                    <div>
                        <countTo :startVal='yearStart' :endVal='yearData.number' :duration='3000'></countTo>
                    </div>
                </div>
            </div>
            <div class="complexbot">
                <div class="venuesPhoto">
                    <img :src=images v-for="(images, i) in image" :key="i">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import countTo from 'vue-count-to';
import {getAjax, timestamp} from "../../../assets/js/websocket";
var ws;
var isClose = false;
export default {
    components: { countTo },
    data () {
        return {
            area:{name : "场馆总面积", number: 0},
            ridership:{name : "当日客流量", number: 0},
            monthData:{name : "当月客流量", number: 0},
            yearData:{name : "当年客流量", number: 0},
            image:[],
            device_id:"",
            ridershipStart:0,
            monthStart:0,
            yearStart:0,
            numTimer:""
        }
    },
    methods: {
        getVenueData(){
			let that = this;
			var data = {
				token: window.getToken(),
				app_id: window.appId,
				time: timestamp(),
			}
			getAjax({
				url: "/map/screen/getSportsVenue",
				method:"GET",
				data:data,
				success:function(res){
					// console.log(res)
                    res.data.forEach((item,index)=>{
                        if(item.title == window.venueName){
                            that.venueId = item.ball_business_id;
                            that.area.number = parseFloat(item.venue_area);
                            // that.ridership.number = parseFloat(item.passenger_flow);
                            that.image = item.images;
                            that.device_id = item.device_id;
                            // console.log(that.device_id)
                            // that.createSocket()
                            that.getTraffic();
                        }
                    })
				},
			})
		},
        // 获取客流量
        getTraffic(){
            let that = this;
            let data;
            let url = window.appHost+"/api/v1/screen/Venue/totalCount?venue_id="+that.venueId
            let result = window.ajaxRequest(url,JSON.stringify(data))
            // console.log(result)
            if(result.code == 1){
                that.ridershipStart = that.ridership.number;
                that.monthStart = that.monthData.number;
                that.yearStart = that.yearData.number;
                that.ridership.number = result.data.dayCount;
                that.monthData.number = result.data.monthCount;
                that.yearData.number = result.data.yearCount;
            }
            
        },
        // socket
        createSocket(){
            let that = this;
            // var url = "ws://115.198.205.71:8898/sendth?id=" + inputId.value
            var url = "ws://font-iot.zjzxsl.com:8898/flow?devices="+JSON.stringify(this.device_id);
            // var url = 'ws://font-iot.zjzxsl.com:8898/flow?devices=["KL000001","KL000007","KL000007","KL000008","KL000011","KL000012"]';
            // console.log(url)
            if(ws){
                console.log("已有socket，不再创建")
            }else{
                 ws = new WebSocket(url);
                //ws.setRequestHeader("data","['a','b','c']");
                // ws.setData("data","['a','b','c']");
                ws.onopen = function (evt) {
                    console.log("OPEN");
                    let interval = setInterval(() => {
                        if (ws) {
                            let s = ""
                            // console.log(s)
                            ws.send(s)
                        } else {
                            clearInterval(interval);
                        }
                    }, 3000)
                }
                ws.onclose = function (evt) {
                    console.log("CLOSE");
                    ws = null;
                    if (!isClose) {
                        setTimeout(() => {
                            that.createSocket();
                        }, 3000)
                    }
                }
                ws.onmessage = function (evt) {
                    // console.log(JSON.parse(evt.data));
                    // console.log(JSON.parse(evt.data).enterCount);
                    let data = JSON.parse(evt.data);
                    let venuesTraffic = {};
                    if(window.localStorage.getItem('venuesTraffic') != null){
                        venuesTraffic = JSON.parse(window.localStorage.getItem('venuesTraffic'));
                    } 

                    // // venuesTraffic = JSON.parse(venuesTraffic)
                    let deviceId = data.deviceId
                    // console.log(deviceId)
                    venuesTraffic[deviceId] = data.enterCount;
                    window.localStorage.setItem('venuesTraffic',JSON.stringify(venuesTraffic))

                    that.ridershipStart = that.ridership.number;
                    that.ridership.number = 0;
                    for(let i in venuesTraffic){
                        that.ridership.number += venuesTraffic[i]
                    }
                    // 新增的客流量
                    let add = that.ridership.number - that.ridershipStart;
                    that.monthStart = that.monthData.number;
                    that.monthData.number += add;
                    that.yearStart = that.yearData.number;
                    that.yearData.number += add;

                    // that.ridership.number = JSON.parse(evt.data).enterCount;
                }
                ws.onerror = function (evt) {
                    console.log("ERROR: " + evt.data);
                }
            }
           
        }
    },
    mounted () {
        this.getVenueData()
        this.numTimer = setInterval(() => {
            this.getVenueData()
        }, 3000);
        // this.createSocket();
    },
    beforeDestroy () {
        this.isClose = true;
        // ws.onclose()
        clearInterval(this.numTimer)
    }
}
</script>

<style scoped>
.complex{
    width:5.05rem;
    height:8.91rem;
    padding:0.2rem;
    position: relative;
    color:#ffffff;
    font-size: 0.20rem;
}

.complexcon{
    width:4.30rem;
    height:0.73rem;
    margin: auto;
}
.complextop{
    font-size: 0.14rem;
    color:#A8DAEB;
    text-align: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin:0.2rem 0rem;
}
.complextop>div{
    width:2.00rem;
    height:0.73rem;
    background:url('../../../assets/images/borderBack.png') no-repeat;
    background-size: 100% 100%;
    margin-top: 0.1rem;
}
.complextop>div>p:nth-child(1){
    margin-top:0.10rem;
}

.complextop>div>div{
    font-size: 0.26rem;
    color:#20D7FE;
}
.complexbot{
    width:4.30rem;
    height: 7.3rem;
    margin: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
}
.complexbot>div{
    width:4.30rem;
    height:6.30rem;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow-y: scroll;
}
.complexbot>div>img{
    width:2.0rem;
    height:1.30rem;
    margin-bottom:0.2rem;
    margin-right: 0.1rem;
}
 /*滚动条样式*/
.venuesPhoto::-webkit-scrollbar {
    width: 0.05rem;    
    height: 0rem;
}


.venuesPhoto::-webkit-scrollbar-thumb {
    border-radius: 0.1rem;
    box-shadow: inset 0 0 0.05rem #bec5ce;
    background: #7EA7E2;
}
.venuesPhoto::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.05rem #7EA7E2;
    border-radius: 0.1rem;
}
</style>