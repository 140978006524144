<template>
<!-- 体育彩票销售额 右上 -->
    <div class="yuetwoapp darkBack">
        <div>
            <p style="color:#fff;font-size:0.18rem">场馆客流排名</p>
        </div>
        <div id="histog" style="width:4.80rem; height:4.90rem;margin-top:0.20rem"></div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            sport: {
                yAxis: [],
                data:[],
            }
        }
    },
    methods: {
        canye(number){
            let that = this;
            // let canye = this.$echarts.init(document.getElementById("histog"));

            let canye = this.$echarts.getInstanceByDom(document.getElementById("histog"))
            if (canye == null) { // 如果不存在，就进行初始化
                canye = this.$echarts.init(document.getElementById("histog"));
            }

            let option = {
                 xAxis: {
                    // max: 'dataMax',
                    show:true,
                    type: 'value',
                    "axisLine":{     //x轴坐标轴
                        "show":false,
                    },
                    axisLabel:{
                        show: false,
                    },
                    
                    axisTick: {		//x轴刻度线
                        show:false
                    },
                    splitLine: {            // 网格线的颜色
                        show: true,
                        lineStyle:{
                            color: ['#2D94D040'],
                            width: 1,
                            type: 'solid',
                        }
                　　}
                },
                grid: {         // 间距
                    left: '0%',  
                    // right: '16%', 
                    top:'2%', 
                    bottom: '1%',  
                    containLabel: true,
                    backgroundColor:"red",
                },
                yAxis: {
                    type: 'category',
                    data: number.yAxis,
                    inverse: true,              // 上下反向
                    animationDuration: 300,
                    animationDurationUpdate: 300,
                    // max: 5,          // y轴显示显示最大的条数 
                    axisLine:{              // y轴左侧的样式
                        show:true,
                        lineStyle: {
                            color: '#2D94D0',
                        }
                    },
                    splitLine:{show: false},
                    axisTick: {                 // y轴刻度线
                        show:false,
                        alignWithLabel: true,
                    },
                    splitArea : {show : false}, 
                    axisLabel: {               // y轴字体的颜色
                        textStyle: {
                            show:true,
                            fontFamily:'微软雅黑',
                            color: "#7EA7E2",
                            fontSize: '0.12rem',
                        },                           
                    },
                },
                series: [{
                    // realtimeSort: true,
                    // name: 'X',
                    type: 'bar',
                    barMaxWidth:20,
                    barWidth : '40%',              // 柱子的宽度
                    data: number.data,
                    itemStyle: {
                        normal: {
                            color: this.$echarts.graphic.LinearGradient(0, 0 , 1, 0, [{
                                offset: 0,
                                color: "#2D94D0" // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: "#3AE6CE" // 100% 处的颜色
                            }], false)
                        }
                    },
                    label: {
                        formatter: "{c}",
                        show: true,
                        position: 'right',
                        valueAnimation: true,
                        color: '#3AE5CE',    // 柱状图上字体的颜色
                        fontSize:'0.14rem',
                    },
                }],
                legend: {
                    show: true
                },
                animationDuration: 0,
                animationDurationUpdate: 3000,
                animationEasing: 'linear',
                animationEasingUpdate: 'linear'
            };
            canye.setOption(option);

            //图表自动缩放
            window.addEventListener("resize",function(){
                canye.resize();
            });
        }
    },
    mounted () {
        window.windowResize();
        var data = {}
        let url = window.appHost+"/api/v1/screen/Venue/sportsVenueFlow"
        let result = window.ajaxRequest(url,JSON.stringify(data),"post")
        // console.log(result)
        if(result.code == 1){
            this.sport = {
                data:[],
                yAxis:[]
            };
            for(let i in result.data){
                this.sport.data.push(result.data[i].totalnum);
                this.sport.yAxis.push(result.data[i].title);
            }
            this.canye(this.sport);
        }
        
    },
}
</script>

<style scoped>
.yuetwoapp{
    width: 5.06rem;
    height:5.83rem;
    padding: 0.20rem;
    position: relative;
}
</style>