window.initAmap2 = () => {
	// $("#a").attr("src","https://webapi.amap.com/maps?v=1.4.15&key=19f93ce96f71a94a0f260817fe096350&plugin=Map3D,AMap.DistrictLayer,AMap.Scale,AMap.ToolBar")
	// $("#b").attr("src","https://a.amap.com/jsapi_demos/static/demo-center/model/js/three.js")
	// $("#c").attr("src","https://a.amap.com/jsapi_demos/static/demo-center/model/js/loaders/MTLLoader.js")
	// $("#d").attr("src","https://a.amap.com/jsapi_demos/static/demo-center/model/js/loaders/LoaderSupport.js")
	// $("#e").attr("src","https://a.amap.com/jsapi_demos/static/demo-center/model/js/loaders/OBJLoader2.js")

	const ZOOM = 17.5; //设置地图层级
	var adCode = 330600; //绍兴市区域代码
	var depth = 2; //显示市等级
	var disProvince; // 创建省份图层
	var colors = {}; // 颜色辅助方法
	var shaoxingJson = './data/shaoxing.json';
	var cityJson =
		'https://zhipao.oss-cn-hangzhou.aliyuncs.com/models/shaoxingGeo.json?versionId=CAEQGRiBgMCfxOuT1RciIDY1ZDAzZmUzZjIxMjQxNjViOTZkYWI3MmE5NTk2MDM1';
	var lineJson = './data/shaoxing-line.json';
	var cityImage = './images/bq2.jpg';
	var markerImg = './images/marker7.gif';
	var markerbottomImg = './images/marker-bottom.gif';
	var buildingZooms = [8, 20]; // 设置层级范围 显示3D 绍兴[楼块] 显示层级区间
	var regionBlockZooms = [1, 10]; // 设置层级范围 绍兴市区域视图 显示层级区间
	var map
	window.map = map = new AMap.Map('amap', {
		viewMode: '3D', //设置3D地图
		zoom: ZOOM, //地图层级
		zooms: [1, 20],
		resizeEnable: true, // 设置地图自适应
		//rotation: -35, //默认地图旋转角度
		pitch: 50, //地图仰角 60
		center: [120.60266,29.984077],
		mapStyle: 'amap://styles/1f65c424888bfb721cfe6a4601c7fdeb', //设置地图
		showBuildingBlock: false, // 显示全局楼宇
		showLabel: true, //显示全局名称
		resizeEnable: true, //全局设置尺寸
		skyColor: '#000', //天空颜色
		//eventSupport: true,
	});
	// ------------------------------------------------------------------ 平面start
	function initPro(code, dep, index, cityStroke, countyStroke) {
		dep = typeof dep == 'undefined' ? 2 : dep;
		adCode = code;
		depth = dep;

		disProvince && disProvince.setMap(null);

		// $("#titless").html(window.screen.width);
		// if(window.screen.width == 960)
		// {
		// 	window.map.setZoomAndCenter(5,[120.601778,29.595973])
		// }
		
		
		disProvince = new AMap.DistrictLayer.Province({
			zIndex: index,
			adcode: [code],
			depth: dep,
			zooms: regionBlockZooms, // 设置层级范围 显示3D 绍兴区域
			height: 8000,
			styles: {
				'fill': function(properties) {
					// properties为可用于做样式映射的字段，包含
					// NAME_CHN:中文名称
					// adcode_pro
					// adcode_cit
					// adcode
					var adcode = properties.adcode;
					return getColorByAdcode(adcode);
				},
				'province-stroke': 'cornflowerblue', //国境线
				'coastline-stroke': '', //海岸线
				'city-stroke': cityStroke, // 中国地级市边界
				'county-stroke': countyStroke, // 中国区县边界
			}
		});
		disProvince.setMap(map);
	}
	var getColorByAdcode = function(adcode) {
		if (!colors[adcode]) {

			if (adcode == 330602) { // 越城区
				colors[adcode] = '#3A66EA';
			} else if (adcode == 330603) { // 柯桥区
				colors[adcode] = '#083395';
			} else if (adcode == 330604) { // 上虞区
				colors[adcode] = '#4794DC';
			} else if (adcode == 330681) { // 诸暨市
				colors[adcode] = '#0D7FD1';
			} else if (adcode == 330683) { // 嵊州市
				colors[adcode] = '#2951C4';
			} else if (adcode == 330624) { // 新昌县
				colors[adcode] = '#4695DC';
			}
		}

		return colors[adcode];
	};

	initPro(adCode, depth, 30, '#69fefd', '#69fefd')
	if(window.screen.width == 960){
		window.lotteryjson.home.data.zoom=8.9
		window.lotteryjson.home.data.center=[120.639576,29.784477]
	}
	
}
window.clearMap2 = () => {
	window.map.clearMap()
}
window.loadModel2 = (obj) => {
			var setObj = {
				title:obj.title,//标题
				objUrl:obj.objUrl,//模型obj路径
				objMtl:obj.objMtl,//模型mtl
				rotateX:obj.rotateX,//模型旋转的角度
				rotateY:obj.rotateY,//模型旋转的角度
				rotateZ:obj.rotateZ,//模型旋转的角度
				objScale:obj.objScale,//物体的局部缩放。默认值是Vector3( 1, 1, 1 )
				objPosition:obj.objPosition, //3d模型定位
				mapPosition:obj.mapPosition,//坐标位置
			};
			console.log(setObj)
			var modelName = setObj.title;
			var scope = this;
			var objLoader = new THREE.OBJLoader2();
			var callbackOnLoad = function ( event ) {
				console.log(AMap)
	          	var object3Dlayer = new AMap.Object3DLayer();
		        var meshes = event.detail.loaderRootNode.children;
		        for(var i=0;i<meshes.length;i++){
		            var vecticesF3 = meshes[i].geometry.attributes.position;
		            var vecticesNormal3 = meshes[i].geometry.attributes.normal;
		            var vecticesUV2 = meshes[i].geometry.attributes.uv;
		            
		            var vectexCount =  vecticesF3.count;

		            MapObject3D = new AMap.Object3D.MeshAcceptLights();

		            var geometry = MapObject3D.geometry;
		           
		            //底部一圈
		            // debugger

		            var c,opacity;

		            var material = meshes[i].material[0]||meshes[i].material;
		            // debugger
		            if(material.map)
		            MapObject3D.textures.push('')
		            
		            c = material.color;
		            opacity = material.opacity
		            
		            // debugger
		            for(var j=0;j<vectexCount;j+=1){
		                var s = j*3;
		                geometry.vertices.push(vecticesF3.array[s],vecticesF3.array[s+2],-vecticesF3.array[s+1]);
		               
		                if(vecticesNormal3) {
		               		geometry.vertexNormals.push(vecticesNormal3.array[s],vecticesNormal3.array[s+2],-vecticesNormal3.array[s+1]);
		                }
		                if(vecticesUV2) {
		               		geometry.vertexUVs.push(vecticesUV2.array[j*2],1-vecticesUV2.array[j*2+1]);
		                }
		                geometry.vertexColors.push(c.r,c.g,c.b,opacity)
		            }
		            // debugger
		            MapObject3D.DEPTH_TEST = material.depthTest
		            // MapObject3D.backOrFront = 'both'
		            MapObject3D.transparent = opacity<1;
		            MapObject3D.scale(setObj.objScale[0],setObj.objScale[1],setObj.objScale[2])
		            MapObject3D.rotateZ(setObj.rotateZ)
					MapObject3D.rotateX(setObj.rotateX)
					MapObject3D.rotateY(setObj.rotateY)
					
		            MapObject3D.position(new AMap.LngLat(setObj.objPosition[0],setObj.objPosition[1]),setObj.objPosition[2],setObj.objPosition[3])
					//MapObject3D.position(new AMap.LngLat(120.588667,30.048099),650,0)
					
		            object3Dlayer.add(MapObject3D)
		        }
	          	window.map.add(object3Dlayer)
			};

			var onLoadMtl = function ( materials ) {
				// for(var i=0;i<materials.length;i+=1){
				// 	materials[i].side=2;
				// }
				objLoader.setModelName( modelName );
				objLoader.setMaterials( materials );
				objLoader.load( setObj.objUrl, callbackOnLoad, null, null, null, false );
			};
			objLoader.loadMtl( setObj.objMtl, null, onLoadMtl );
			new AMap.Text({
				text:setObj.title,
				position:setObj.mapPosition,
				height:650,
				verticalAlign:'bottom',
				map:window.map,
				style:{
					'background-color':'rgb(0 0 0 / 39%)',
					'border-color':'rgb(0 0 0 / 39%)',
					'font-size':'16px',
					'color':'#fff'
				}
			})
		}
		
// loadModel({
// 	title: "绍兴科技馆", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/kjg.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/kjg.mtl", //模型mtl
// 	objPosition: [120.588667, 30.047599, 700, 0], //3d模型定位
// 	mapPosition: [120.584647, 30.048099], //坐标位置
// 	objScale: [18, 18, 18],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 18,
// })
// loadModel({
// 	title: "元培中学", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/yuanpeizhongxue.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/yuanpeizhongxue.mtl", //模型mtl
// 	objPosition: [120.563741, 30.004446, 50, 0], //3d模型定位
// 	mapPosition: [120.564241, 30.005346], //坐标位置
// 	objScale: [0.008, 0.008, 0.008],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 0,
// })
// loadModel({
// 	title: "御景华庭", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/yujinghuating.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/yujinghuating.mtl", //模型mtl
// 	objPosition: [120.600286, 30.005568, 10, 0], //3d模型定位
// 	mapPosition: [120.600586, 30.006168], //坐标位置
// 	objScale: [0.015, 0.015, 0.015],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 0,
// })
// loadModel({
// 	title: "阳明中学", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/yangmingzhongxue.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/yangmingzhongxue.mtl", //模型mtl
// 	objPosition: [120.550015, 30.004605, 10, 0], //3d模型定位
// 	mapPosition: [120.553585, 30.006905], //坐标位置
// 	objScale: [0.01, 0.01, 0.01],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 5,
// })
// loadModel({
// 	title: "轻纺城", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/qingfangcheng.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/qingfangcheng.mtl", //模型mtl
// 	objPosition: [120.487567,30.059518, 10, 0], //3d模型定位
// 	mapPosition: [120.489267,30.059518], //坐标位置
// 	objScale: [0.004, 0.004, 0.004],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 30,
// })
// loadModel({
// 	title: "陆游故里", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/luyouguli.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/luyouguli.mtl", //模型mtl
// 	objPosition: [120.527558,30.014281, 10, 0], //3d模型定位
// 	mapPosition: [120.529758,30.014281], //坐标位置
// 	objScale: [0.004, 0.004, 0.004],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 35,
// })
// loadModel({
// 	title: "鲁迅故里建筑群", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/luxungulijianzhuqun.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/luxungulijianzhuqun.mtl", //模型mtl
// 	objPosition: [120.578178,29.989213, 10, 0], //3d模型定位
// 	mapPosition: [120.584378,29.993213], //坐标位置
// 	objScale: [0.005, 0.005, 0.005],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 0,
// })
// // TODO 有问题
// loadModel({
// 	title: "府山公园", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/fushangongyuan.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/fushangongyuan.mtl", //模型mtl
// 	objPosition: [120.574759,29.999658, 10, 0], //3d模型定位
// 	mapPosition: [120.574759,29.999558], //坐标位置
// 	objScale: [10,10,10],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 0,
// })
// // TODO 有问题
// loadModel({
// 	title: "鲁迅故里", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/luxunguli.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/luxunguli.mtl", //模型mtl
// 	objPosition: [120.586733,29.992077, 10, 0], //3d模型定位
// 	mapPosition: [120.586733,29.992077], //坐标位置
// 	objScale: [100,100,100],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: -90,
// })
// loadModel({
// 	title: "柯岩风景区", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/keyanfengjingqu.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/keyanfengjingqu.mtl", //模型mtl
// 	objPosition: [120.470893,30.049621, 10, 0], //3d模型定位
// 	mapPosition: [120.470893,30.049621], //坐标位置
// 	objScale: [100,100,100],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: -90,
// })
// loadModel({
// 	title: "绍兴市体育会展馆", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/tiyuguan.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/tiyuguan.mtl", //模型mtl
// 	objPosition: [120.575148,30.055685, -690, 0], //3d模型定位
// 	mapPosition: [120.575148,30.055685], //坐标位置
// 	objScale: [12,12, 12],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 45,
// })
// loadModel({
// 	title: "绍兴市羽毛球馆", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/yumaoqiuguan.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/yumaoqiuguan.mtl", //模型mtl
// 	objPosition: [120.575088,30.053812, -690, 0], //3d模型定位
// 	mapPosition: [120.574718,30.054812], //坐标位置
// 	objScale: [12,12, 12],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 45,
// })
// loadModel({
// 	title: "游泳馆", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/youyongguan.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/youyongguan.mtl", //模型mtl
// 	objPosition: [120.574505,30.051061, 1700, 0], //3d模型定位
// 	mapPosition: [120.575405,30.051061], //坐标位置
// 	objScale: [20,20, 20],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 0,
// })
// loadModel({
// 	title: "绍兴环球纽约国际中心", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/huanqiuniuyueguojizhongxin.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/huanqiuniuyueguojizhongxin.mtl", //模型mtl
// 	objPosition: [120.602315,30.007833, -80, 0], //3d模型定位
// 	mapPosition: [120.602015,30.005433], //坐标位置
// 	objScale: [500,500, 500],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 95,
// })
// loadModel({
// 	title: "绍兴世茂天际中心", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/shaoxingshimaotianjizhongxin.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/shaoxingshimaotianjizhongxin.mtl", //模型mtl
// 	objPosition: [120.603539,30.004218, 0, 0], //3d模型定位
// 	mapPosition: [120.603539,30.004218], //坐标位置
// 	objScale: [400,400, 400],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: -24,
// })
// loadModel({
// 	title: "镜湖大桥", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/jinghudaqiao.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/jinghudaqiao.mtl", //模型mtl
// 	objPosition: [120.577358,30.039207, -120, 0], //3d模型定位
// 	mapPosition: [120.579458,30.041207], //坐标位置
// 	objScale: [500,500, 500],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: -24,
// })
// loadModel({
// 	title: "绍兴博物馆", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/shaoxingbowuguan.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/shaoxingbowuguan.mtl", //模型mtl
// 	objPosition: [120.572127,29.997623, 0, 0], //3d模型定位
// 	mapPosition: [120.572327,29.997923], //坐标位置
// 	objScale: [60,60, 60],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: -24,
// })
// // TODO 有问题的
// loadModel({
// 	title: "文化馆", //标题
// 	objUrl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/wenhuaguan.obj", //模型obj路径
// 	objMtl: "https://zhipao-action.oss-cn-shanghai.aliyuncs.com/models/obj/wenhuaguan.mtl", //模型mtl
// 	objPosition: [120.582185,30.048362, 0, 0], //3d模型定位
// 	mapPosition: [120.582185,30.048362], //坐标位置
// 	objScale: [300,300, 300],
// 	rotateX: 0,
// 	rotateY: 0,
// 	rotateZ: 0,
// })
