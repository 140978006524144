import { render, staticRenderFns } from "./stadiumone.vue?vue&type=template&id=530b9576&scoped=true&"
import script from "./stadiumone.vue?vue&type=script&lang=js&"
export * from "./stadiumone.vue?vue&type=script&lang=js&"
import style0 from "./stadiumone.vue?vue&type=style&index=0&id=530b9576&scoped=true&rel=stylesheet%2Fless&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "530b9576",
  null
  
)

export default component.exports